@import '~antd/dist/antd.css';
@import url("https://use.typekit.net/rrq3cgj.css");

.app {

  font-family: blambot-pro, sans-serif;

  font-weight: 400;
  
  font-style: normal;
  overflow-wrap: break-word;
  
}

.header {
  background-color: #111;
  margin-bottom: 0;
  padding: 10px 2vw 10px 2vw;
}

.headerString {
  margin-bottom: 0;
  color: white;
  font-size: 40px;
}

.site-wrapper {
  border: black 8px solid;
  background-color: rgb(225, 225, 225);
}

.site-layout-content {
  min-height: 80vh;
  margin: 2vw;
  padding: 2vw;
  background: #b64040;
  border: black 8px solid;
}

.whiteContent {
  background-color: rgb(237, 237, 237);
}

.mid-site-layout-content {
  min-height: 80vh;
  margin: 2vw;
  background: #b64040;
  border: black 8px solid;
}

.pageTopper {
  margin-bottom: 0;
  padding: 2vw;
  background: #ece3be;
  border: black 8px solid;
}

.charDesc {
  margin: 2vw;
}

.logo {
  float: left;
  display: inline;
  background-color: red;
  display: inline;
  border-right: "10px";
}

.footer {
  background-color: black;
  text-align: center;
  color: gray;
}

.ant-layout-header {
  background-color: black;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #40B6B6;
}

.ant-menu-item:hover {
  background-color: #478fb0 !important;
}


.card {
  background-color: rgb(237, 237, 237);
  border: black 8px solid;
}
.card:hover{
  background-color: white;
  box-shadow: 10px 10px;
  border: black 8px solid;
  border-top: #40B6B6 8px solid;;
}

.tag {
  border: black 2px solid;
  font-weight: bold;
}

.searchBar {
  border: black 5px solid;
  box-shadow: 0px 0px;
}

.tag {
  padding-left: 8px;
  padding-right: 8px; 
  padding-top: 4px;
  padding-bottom: 4px;
  color: white;
  border: black 4px solid;
  height: 32px;
}

.transition{
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition:all 0.5s ease-in-out;
  -o-transition:all 0.5s ease-in-out;
  transition:all 0.5s ease-in-out;
}

.searchBar:hover {
  border: #308352 5px solid;
  box-shadow: 10px 10px;
}

.searchBarFocused {
  border:#40B6B6 5px solid;
  box-shadow: 20px 20px;

}

.ant-input-wrapper {
  width: 100.2%;
}

.characterLongDescString {
  font-weight: 600;
  line-height: 1.4;
  font-size: 20px;
  margin-bottom: 0;
}

.randomComicsString {
  font-weight: 600;
  line-height: 1.4;
  font-size: 30px;
  margin-bottom: 20px;
}

.tldrText {
  line-height: 48px;
  font-size: large;
  color: black;
  font-weight:900;
}

.specialMenuText {
  
  color: gold
}

.largeDiv {
  margin-top: 32px;
  margin-bottom: 32px;
}

.socialMediaButton {
  color: white;
  font-weight: 900;
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  min-width: 250px;
  border: black 8px solid;
  margin-right: 16px;
}

.socialMediaButtonsWrapper {
  margin-top: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.discordButton {
  background-color: #7289da;
}

.instagramButton {
  background-color: #E1306C;
}

.discordButton:hover {
  background-color: #4760b8;
}

.instagramButton:hover {
  background-color: #e01458;
}

.exploreSub {
  margin-bottom: 2vw;
}